import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const AboutPage = ({
                     data: {
                       site
                     }
                   }) => {
  return (
    <Layout>
      <Helmet>
        <title>Om meg — {site.siteMetadata.title}</title>
        <meta name="description" content={"About us page of " + site.siteMetadata.description} />
      </Helmet>
      <div className="two-grids -contact">
        <div className="post-thumbnail" style={{ backgroundImage: `url('/assets/image-5.jpg')`, marginBottom: 0 }}>
          <h1 className="post-title">Om meg</h1>
          <p>Lær av erfarne fagfolk &rarr;</p>
        </div>
        <div className="form-container">
          I 2016 leverte jeg min mastergrad i informatikk ved Insistutt for informatikk, Universitetet i Oslo. Siden den gang
          har jeg i hovedsak jobbet med utvikling av fagsystemer som konsulent for justis og energi sektoren, men har også
          gått inn i mindre oppdrag for andre sektorer. Min lidenskap ligger i skjæringspunktet mellom teknologi og menneske,
          hvor teknologien øker effektiviteten og presisjonen til brukerne.
          <hr />
          <b>Indigo Consulting Christian Bergum Bergersen</b><br />
          Org nr: NO999 598 188MVA<br/>
          Adresse: Symreveien 37, 1470 Lørenskog<br />
          E-post: christian@indigocons.no<br />
        </div>
      </div>
    </Layout>
  );
};
export default AboutPage;
export const pageQuery = graphql`
  query AboutPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;